/* eslint-disable */
import React, { useEffect, useState, useRef } from "react"
import BpmnModeler from "bpmn-js/lib/Modeler"
import BpmnViewer from "bpmn-js/lib/NavigatedViewer"
import "bpmn-js/dist/assets/diagram-js.css"
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css"
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css"
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css"
import "./draw-funnel.scss"
import {
  customPaletteModule,
  customReplaceMenuModule,
  customContextPadModule,
  customRendererModule,
  customPropertiesProvider
} from "./bpmn-modules"
import customElementsDescriptor from "./bpmn-modules/custom-moddle.json"
import {
  showErrorToastr,
  showSuccessToastr,
  showWarningToastr,
} from "../../components/Common/toastr"
import { toTitleCaseWithSpacing } from "../../utils/bpmn"
import ConfirmModal from "../../components/Common/ConfirmModal"
import { useNavigate, useParams } from "react-router-dom"
import {
  callGetApi,
  callPostApi,
  callPutApi,
  callDeleteApi,
} from "../../utils/api"
import { useFunnelData } from "../../contexts/FunnelDataContext"
import { 
  BpmnPropertiesPanelModule
} from "bpmn-js-properties-panel"
import 'bpmn-js/dist/assets/bpmn-js.css';
import '@bpmn-io/properties-panel/assets/properties-panel.css';

const MyBpmnComponent = () => {
  const { mode, funnelId } = useParams()
  const history = useNavigate()
  const [modeler, setModeler] = useState(null)
  const isModelerInitialized = useRef(false)
  const { funnelData, updateFunnelData } = useFunnelData()
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [confirmAction, setConfirmAction] = useState({
    action: null,
    message: "",
  })
  const path = "/funnels"
  const [currentFunnel, setCurrentFunnel] = useState(null)
  const isEdit = mode === "edit"
  const isView = mode === "view"
  const isCreate = mode === "create"

  useEffect(() => {
    var body = document.body
    body.classList.toggle("vertical-collpsed")
    body.classList.toggle("sidebar-enable")
    body.style.minHeight = 0
  }, [])

  useEffect(() => {
    if (isEdit && funnelData.id !== funnelId) history(-1)
    else fetchFunnel()
  }, [mode])

  useEffect(() => {
    if (currentFunnel && isModelerInitialized?.current) {
      destroyModeler({ mount: true })
    } else if (currentFunnel) {
      initializeModeler()
    }

    return destroyModeler
  }, [currentFunnel])

  const destroyModeler = (props) => {
    const { mount } = props ?? { mount: false }
    if (modeler) {
      mount &&
        modeler.on("diagram.destroy", () => {
          initializeModeler()
        })
      modeler.destroy()
    }
  }

  const initializeModeler = async () => {
    const bpmnModeler =
      isEdit || isCreate
        ? new BpmnModeler({
          container: '#bpmn-container',
          propertiesPanel: { parent: '#properties-container' },
          additionalModules: [
            BpmnPropertiesPanelModule,
            { __init__: ['customPropertiesProvider'], customPropertiesProvider: ['type', customPropertiesProvider] },
            customReplaceMenuModule,
            customPaletteModule,
            customContextPadModule,
            customRendererModule,
          ],
          moddleExtensions: {
            mycustom: customElementsDescriptor,
          },
        })
        : new BpmnViewer({
            container: "#bpmn-container",
            additionalModules: [customRendererModule],
            moddleExtensions: {
              mycustom: customElementsDescriptor,
            },
          })

    const eventBus = bpmnModeler.get("eventBus")

    const messageContainer = document.getElementById("custom-message-container")

    if (!messageContainer) {
      console.error("Message container not found!")
      return
    }
  
    eventBus.on("selection.changed", function (event) {
      const { newSelection } = event
      const selectedElement = newSelection[0]
      const messageContainer = document.getElementById("custom-message-container")
      const propertiesPanel = bpmnModeler.get("propertiesPanel")

      if (!selectedElement || selectedElement.type === "bpmn:Process") {
        messageContainer.style.display = "block"
        if (propertiesPanel) {
          propertiesPanel.detach()
        }
      } else {
        messageContainer.style.display = "none"
        if (propertiesPanel) {
          propertiesPanel.attachTo("#properties-container")
        }
      }
    })
    await new Promise((resolve) => {
      if (currentFunnel?.xml) {
        bpmnModeler?.importXML(currentFunnel.xml).then((result) => {
          if (result.errors?.length > 0) {
            showErrorToastr(
              `An error ocurred - ${JSON.stringify(result.errors?.[0])}`
            )
          } else if (result.warnings?.length > 0) {
            showWarningToastr(
              `Saved completed with warnings - ${JSON.stringify(
                result.warnings?.[0]
              )}`
            )
          } else {
            console.log("BPMN diagram loaded successfully!")
          }
        })
      } else {
        bpmnModeler?.createDiagram()
      }
      setModeler(bpmnModeler)
      isModelerInitialized.current = true
      resolve()
    })
  }

  const fetchFunnel = () => {
    if (isEdit || isView) {
      callGetApi(
        true,
        `/funnel/file/${funnelId}`,
        xmlData => {
          setCurrentFunnel({ ...funnelData, xml: xmlData })
        },
        xmlError => {
          showErrorToastr(`An error ocurred - ${xmlError}`)
          console.error("Failed to fetch funnel XML:", xmlError)
        }
      )
    } else if (isCreate) {
      setCurrentFunnel(funnelData)
    }
  }

  const saveBpmnToFile = () => {
    const issues = validateDiagram()

    if (issues.length > 0) {
      showErrorToastr(
        `Cannot save due to the following issues:\n${issues.join("\n")}`
      )
    } else {
      modeler?.saveXML({format: true}).then(({ xml }) => {
        const blob = new Blob([xml], { type: "application/xml" })
        const link = document.createElement("a")
        link.href = URL.createObjectURL(blob)
        link.download = "diagram.bpmn"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      showSuccessToastr("Model dowloaded successfully")
    }
  }

  const saveXML = () => {
    const issues = validateDiagram()

    if (issues.length > 0) {
      showErrorToastr(
        `Cannot save due to the following issues:\n${issues.join("\n")}`
      )
    } else {
      modeler?.saveXML().then(({ xml }) => {
        saveFunnel(xml)
      })
    }
  }

  const handleModalConfirmation = () => {
    if (confirmAction?.action) {
      switch (confirmAction.action) {
        case "back":
          history(path)
          break
        case "reset":
          resetModel(true)
          break
        case "delete":
          deleteModel(true)
          break
        default:
          break
      }
    }
    setShowConfirmModal(false)
  }

  const resetModel = async (confirmed = false) => {
    if (!confirmed) {
      setConfirmAction({
        action: "reset",
        message: "Are you sure you want to reset the model?",
      })
      setShowConfirmModal(true)
      return
    }
    fetchFunnel()
  }

  const deleteModel = async (confirmed = false) => {
    if (!confirmed) {
      setConfirmAction({
        action: "delete",
        message: "Are you sure you want to delete the model?",
      })
      setShowConfirmModal(true)
      return
    }

    try {
      if (!currentFunnel?.id) {
        showErrorToastr("An error ocurred deleting the funnel")
        return
      }

      const queryParams = new URLSearchParams({
        updatedBy: currentFunnel.email,
      }).toString()

      callDeleteApi(
        true,
        `/funnel/${currentFunnel.id}?${queryParams}`,
        () => {
          showSuccessToastr("Funnel successfully deleted")
          history(path)
        },
        errorMessage => {
          console.error("Failed to delete funnel:", errorMessage)
        }
      )
    } catch (error) {
      showErrorToastr(`Cannot delete model.`)
    }
  }

  const saveFunnel = xml => {
    if (!currentFunnel || (isEdit && !funnelId)) {
      showErrorToastr("An error ocurred saving the funnel")
      return
    }

    const funnelData = {
      title: currentFunnel.title,
      description: currentFunnel.description,
      store: currentFunnel.store,
      created_by: isCreate ? currentFunnel.email : undefined,
      updated_by: isEdit ? currentFunnel.email : undefined,
      file: xml,
    }
    handleApiCall(
      isEdit ? `/funnel/${funnelId}` : "/funnel",
      funnelData,
      ({ id, title, description }) => {
        showSuccessToastr("Model saved successfully")
        updateFunnelData({ id, title, description }, false)

        if (isCreate) {
          history(`/funnels/draw-your-funnel/edit/${id}`, {
            replace: true,
          })
        }

        if (isEdit) {
          fetchFunnel()
        }
      },
      errorMessage => {
        showErrorToastr(`Failed to save model: ${errorMessage}`)
      }
    )
  }

  const handleApiCall = (
    apiPath,
    funnelData,
    successCallback,
    errorCallback
  ) => {
    const formData = new FormData()
    formData.append("title", funnelData.title)
    formData.append("description", funnelData.description)
    formData.append("store", funnelData.store)
    formData.append("is_active", true)

    if (funnelData.created_by) {
      formData.append("created_by", funnelData.created_by)
    }

    if (funnelData.updated_by) {
      formData.append("updated_by", funnelData.updated_by)
    }

    formData.append(
      "file",
      new Blob([funnelData.file], { type: "application/xml" }),
      "diagram.bpmn"
    )

    if (isEdit) {
      callPutApi(true, apiPath, formData, successCallback, errorCallback)
    } else {
      callPostApi(true, apiPath, formData, successCallback, errorCallback)
    }
  }

  const goBack = () => {
    setConfirmAction({
      action: "back",
      message:
        "If you have unsaved changes it will be removed. Are you sure you want to leave?",
    })
    setShowConfirmModal(true)
  }

  const validateDiagram = () => {
    let issues = []

    const requiredFieldsByType = {
      "mycustom:quiz": { label: "Quiz", fields: ["name"] },
      "mycustom:offer": { label: "Offer", fields: ["name", "url", "sku"] },
      "mycustom:upsell": { label: "Upsell", fields: ["retail_price", "final_price", "product"] },
      "mycustom:downsell": { label: "Downsell", fields: ["retail_price", "final_price", "product"] },
      "mycustom:landing": { label: "Landing", fields: ["url"] },
      "mycustom:popup": { label: "Pop up", fields: ["type"] },
      "mycustom:checkout": { label: "Checkout", fields: ["hc_funnel"] },
      "bpmn:ExclusiveGateway": { label: "Gateway", fields: ["field"] },
      "bpmn:SequenceFlow": { label: "Flow", fields: ["value"] },
    }

    modeler?.get("elementRegistry").forEach(element => {
      const businessObject = element.businessObject
      const { fields: requiredFields, label } =
        requiredFieldsByType[element.type] || {}

      if (
        element.type === "bpmn:SequenceFlow" &&
        element.source.type !== "bpmn:ExclusiveGateway"
      )
        return

      if (requiredFields) {
        requiredFields?.forEach(field => {
          if (!businessObject[field]) {
            issues.push(
              `${label} is missing the ${toTitleCaseWithSpacing(field)}.`
            )
          }
        })
      }
    })

    return issues
  }

  return (
      <div className="some">
        <div className="bpmn" id="bpmn-container">
          <div
            className="funnel-details"
            style={{ display: "flex", alignItems: "center", gap: 4 }}
          >
            <div
              style={{
                backgroundColor: "#142541",
                width: 30,
                height: 30,
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img style={{ width: 20, height: 20 }} src="/icon-home.png"></img>
            </div>
            <div style={{marginLeft: "2%"}}>
              <p style={{ padding: 0, margin: 0 }}>
                <b>Title:</b> {currentFunnel?.title}
              </p>
              <p style={{ padding: 0, margin: 0 }}>
                <b>Tag:</b> {currentFunnel?.description}
              </p>
            </div>
            <div style={{marginLeft: "2%"}}>
              <p style={{ padding: 0, margin: 0 }}><b>ID:</b> {currentFunnel?.id}</p>
              <p style={{ padding: 0, margin: 0 }}><b>Status:</b> {currentFunnel?.isActive ? 'Active' : 'Inactive'}</p>
            </div>
          </div>
          <div className="bpmn-actions-group">
            <button className="download-btn" onClick={goBack} title="Back">
              <i className="fa fa-arrow-left"></i>
            </button>
            {(isEdit || isCreate) && (
              <button className="download-btn" onClick={saveXML} title="Save">
                <i className="fa fa-save"></i>
              </button>
            )}
            <button
              className="download-btn"
              onClick={saveBpmnToFile}
              title="Download"
            >
              <i className="fa fa-download"></i>
            </button>
            {isEdit && (
              <>
                <button
                  className="download-btn"
                  onClick={() => resetModel()}
                  title="Cancel"
                >
                  <i className="fa fa-ban"></i>
                </button>
                <button
                  className="download-btn"
                  onClick={() => deleteModel()}
                  title="Delete"
                >
                  <i className="fa fa-trash"></i>
                </button>
              </>
            )}
          </div>
        </div>
        <div id="properties-container" className="properties">
          <div id="custom-message-container">
            <div style={{height: '55px', backgroundColor: 'hsl(225, 10%, 95%)'}}>
              <h4 style={{
                color: 'black',
                padding: '14px',
                fontSize: '12px',
                fontFamily: 'sans-serif',
                textTransform: 'uppercase',
                fontWeight: 600,
                }}>Properties</h4>
            </div>
            <div style={{borderTop: 'solid 5px hsl(225, 10%, 75%)'}}>
             <p class='no-selection-message'>Select a node to see its properties</p>
            </div>
          </div>
        </div>
        <ConfirmModal
          show={showConfirmModal}
          onConfirmClick={handleModalConfirmation}
          onCloseClick={() => setShowConfirmModal(false)}
          message={confirmAction?.message}
        />
      </div>
  )
}

export default MyBpmnComponent
