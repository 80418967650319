import React, { useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Button, Label } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { callDeleteApi, callPutApi } from "../../../../utils/api"
import { showSuccessToastr } from "../../../../components/Common/toastr"
import ConfirmModal from "../../../../components/Common/ConfirmModal"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

const OfferSets = ({
  validationType,
  setIsOfferOpen,
  setEditOfferSetData,
  shop,
}) => {
  const history = useNavigate()
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [path, setPath] = useState("")

  let previewUrl = null
  if (shop.includes("truekind")) {
    previewUrl = process.env.REACT_APP_TRUEKIND_URL
  } else if (shop.includes("revel")) {
    previewUrl = process.env.REACT_APP_REVELBEAUTY_URL
  } else if (shop.includes("spa")) {
    previewUrl = process.env.REACT_APP_THESPADR_URL
  } else {
    previewUrl = process.env.REACT_APP_SHAPEES_URL
  }

  const handleDeleteOffer = offerId => {
    callDeleteApi(
      true,
      `/upselling/offers/${offerId}?shop=${shop}`,
      () => onDeleteOfferSuccess(offerId),
      onDeleteOfferFail
    )
  }

  const onDeleteOfferSuccess = offerId => {
    showSuccessToastr("Offer Deleted Successfully!")
    const updatedOffer = validationType.values.offers.filter(
      offer => offerId !== offer.id
    )
    validationType.setFieldValue("offers", updatedOffer)
  }

  const onDeleteOfferFail = () => {}

  const handleDeleteOfferSets = offerSets => {
    let payload = {
      ...offerSets,
      isActive: false,
    }
    callPutApi(
      true,
      `/upselling/funnels/offer-sets/${offerSets.id}?shop=${shop}`,
      payload,
      () => onDeleteOfferSetsSuccess(payload),
      onDeleteOfferSetsFail
    )
  }

  const onDeleteOfferSetsSuccess = offerSet => {
    showSuccessToastr("Offer Set Deleted Successfully!")
    const updatedOfferSets = validationType.values.offerSets.map(offer => {
      if (offerSet.id === offer.id) {
        return offerSet
      } else {
        return offer
      }
    })
    validationType.setFieldValue("offerSets", updatedOfferSets)
  }

  const onDeleteOfferSetsFail = () => {}

  const onConfirmClick = () => {
    setShowConfirmModal(false)
    history(path)
  }

  // Drag and Drop Handling
  const handleOnDragEnd = result => {
    if (!result.destination) return
    let offersWithParent = []
    let offersWithoutParent = []
    validationType.values.offers?.map(el => {
      if (el?.parentId) {
        offersWithParent.push(el)
      } else {
        offersWithoutParent.push(el)
      }
    })
    const items = Array.from(offersWithoutParent)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    // Update the order based on the new position
    const updatedOffers = items.map((offer, index) => ({
      ...offer,
      order: index + 1,
    }))
    updatedOffers?.map(el => {
      if (!el?.parentId) {
        callPutApi(
          true,
          `/upselling/offers/${el.id}?shop=${shop}`,
          {
            order: el.order,
          },
          () => {},
          () => {}
        )
      }
    })
    validationType.setFieldValue("offers", [
      ...updatedOffers,
      ...offersWithParent,
    ])
  }
  return (
    <div>
      <div>
        <Row>
          {validationType.values.offerSets?.map((offerSet, index) => {
            // if (validationType.values.priority !== "1" && index !== 0) {
            //   return null
            // }
            if (!offerSet?.isActive) {
              return null
            }

            let hasUpsells = null
            let previewId = null
            validationType.values.offers.map(el => {
              if (offerSet.id === el.offerSetId) {
                hasUpsells = {
                  isInclude: true,
                  id: el.offerSetId,
                }
              }
              if (offerSet.id === el.offerSetId && !previewId) {
                previewId = el.id
              }
            })

            return (
              <Col lg={12} key={index}>
                <div className="offer-set-main-container">
                  <CardTitle>
                    <div className="offer-section-action">
                      {validationType.values.priority === "1" ? (
                        <span>
                          {offerSet.name} - ({offerSet.priority}%)
                        </span>
                      ) : (
                        <span>Offer</span>
                      )}
                      <div className="offer-action">
                        {validationType.values.priority === "1" && (
                          <Button
                            type="button"
                            color="link"
                            className="btn btn-link waves-effect"
                            onClick={() => {
                              setEditOfferSetData(offerSet)
                              setIsOfferOpen(true)
                            }}
                          >
                            Edit
                          </Button>
                        )}
                        {hasUpsells?.isInclude && (
                          <Button
                            type="button"
                            color="link"
                            className="btn btn-link waves-effect"
                            onClick={() => {
                              if (hasUpsells?.isInclude) {
                                window.open(
                                  `${previewUrl}/hc/upselling/preview/${offerSet?.funnelId}/${previewId}`
                                )
                              }
                            }}
                          >
                            Preview
                          </Button>
                        )}
                        {index !== 0 && (
                          <Button
                            type="button"
                            color="link"
                            className="btn btn-link waves-effect"
                            onClick={() => handleDeleteOfferSets(offerSet)}
                          >
                            Delete
                          </Button>
                        )}
                        <Button
                          type="button"
                          color="link"
                          className="btn btn-link waves-effect"
                          onClick={() => {
                            setPath(
                              `/add-offer/${offerSet.funnelId}?offerSetId=${offerSet.id}&shop=${shop}`
                            )
                            setShowConfirmModal(true)
                          }}
                        >
                          Add Offer
                        </Button>
                      </div>
                    </div>
                  </CardTitle>
                </div>

                {validationType.values.offers?.length > 0 &&
                hasUpsells?.isInclude ? (
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId={`offers-${offerSet.id}`}>
                      {provided => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <Card>
                            <CardBody>
                              <div className="mt-3">
                                {validationType.values.offers
                                  .filter(
                                    product =>
                                      !product.parentId &&
                                      product.offerSetId === hasUpsells.id
                                  )
                                  .sort((a, b) => {
                                    if (a.order === null) return 1
                                    if (b.order === null) return -1
                                    return a.order - b.order
                                  })
                                  .map((product, index) => (
                                    <Draggable
                                      key={product.id}
                                      draggableId={product.id.toString()}
                                      index={index}
                                    >
                                      {provided => (
                                        <div
                                          key={index}
                                          className="upsells-product-box"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <div className="upsells-product-container parent-product-container">
                                            <img
                                              src={product.image}
                                              alt={product.name}
                                            />
                                            <div className="offer-details">
                                              <div className="offer-content">
                                                <Label className="form-label offer-title">
                                                  {product.name}
                                                </Label>
                                                <span
                                                  className={
                                                    product.active
                                                      ? "status-active"
                                                      : "status-inactive"
                                                  }
                                                >
                                                  {product.active
                                                    ? "Active"
                                                    : "Inactive"}
                                                </span>
                                              </div>
                                              <div className="offer-action">
                                                <Button
                                                  type="button"
                                                  color="secondary"
                                                  size="sm"
                                                  onClick={() =>
                                                    handleDeleteOffer(
                                                      product.id
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </Button>
                                                <Button
                                                  type="button"
                                                  color="secondary"
                                                  size="sm"
                                                  onClick={() => {
                                                    setPath(
                                                      `/edit-offer/${product.funnelId}/${product.id}?shop=${shop}`
                                                    )
                                                    setShowConfirmModal(true)
                                                  }}
                                                >
                                                  Edit
                                                </Button>
                                                <Button
                                                  type="button"
                                                  color="secondary"
                                                  size="sm"
                                                  onClick={() => {
                                                    setPath(
                                                      `/add-offer/${offerSet.funnelId}?offerSetId=${offerSet.id}&parentId=${product.id}&shop=${shop}`
                                                    )
                                                    setShowConfirmModal(true)
                                                  }}
                                                >
                                                  Add Downsell
                                                </Button>
                                              </div>
                                            </div>
                                          </div>

                                          <div>
                                            {validationType.values.offers
                                              .filter(
                                                childProduct =>
                                                  childProduct.parentId ===
                                                  product.id
                                              )
                                              .map((childProduct, index_) => (
                                                <div
                                                  key={index_}
                                                  className="upsells-product-container downsell-product-container"
                                                >
                                                  <img
                                                    src={childProduct.image}
                                                    alt={childProduct.name}
                                                  />
                                                  <div className="offer-details">
                                                    <div className="offer-content">
                                                      <Label className="form-label offer-title">
                                                        {childProduct.name}
                                                      </Label>
                                                      <span
                                                        className={
                                                          childProduct.active
                                                            ? "status-active"
                                                            : "status-inactive"
                                                        }
                                                      >
                                                        {childProduct.active
                                                          ? "Active"
                                                          : "Inactive"}
                                                      </span>
                                                    </div>
                                                    <div className="offer-action">
                                                      <Button
                                                        type="button"
                                                        color="secondary"
                                                        size="sm"
                                                        onClick={() =>
                                                          handleDeleteOffer(
                                                            childProduct.id
                                                          )
                                                        }
                                                      >
                                                        Delete
                                                      </Button>
                                                      <Button
                                                        type="button"
                                                        color="secondary"
                                                        size="sm"
                                                        onClick={() => {
                                                          setPath(
                                                            `/edit-offer/${childProduct.funnelId}/${childProduct.id}?shop=${shop}`
                                                          )
                                                          setShowConfirmModal(
                                                            true
                                                          )
                                                        }}
                                                      >
                                                        Edit
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </div>
                                              ))}
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided.placeholder}
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <Card>
                    <CardBody>Please create some offers...</CardBody>
                  </Card>
                )}
              </Col>
            )
          })}
        </Row>
        <ConfirmModal
          show={showConfirmModal}
          onConfirmClick={onConfirmClick}
          onCloseClick={() => setShowConfirmModal(false)}
          message="If you have unsaved changes it will be removed. Are you sure you want to leave?"
        />
      </div>
    </div>
  )
}

export default OfferSets
