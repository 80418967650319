import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  Table,
  Card,
  CardBody,
  Spinner,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap"
import "./list-funnels.scss"
import CreateFunnelModal from "components/BPMN/CreateFunnelModal"
import ConfirmModal from "components/Common/ConfirmModal"
import { callGetApi, callDeleteApi, callPutApi } from "utils/api"
import { formatDate } from "utils/bpmn"
import { showErrorToastr, showSuccessToastr } from "components/Common/toastr"
import { allShopsEnv } from "utils/shop"
import { useFunnelData } from "contexts/FunnelDataContext"
import { getFriendlyErrorMessage } from "utils/errorHandling"

const ListFunnels = () => {
  const [showModal, setShowModal] = useState(false)
  const [currentFunnel, setCurrentFunnel] = useState(null)
  const [funnels, setFunnels] = useState([])
  const [isDuplicating, setIsDuplicating] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [confirmAction, setConfirmAction] = useState({
    action: null,
    funnelData: null,
  })
  const [store, setStore] = useState(null)
  const [searchURL, setSearchURL] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [itemsPerPage] = useState(5)
  const { updateFunnelData } = useFunnelData()
  const [dropdownOpen, setDropdownOpen] = useState({})

  const navigate = useNavigate()

  useEffect(() => {
    fetchFunnels(currentPage)
  }, [currentPage, store, searchURL])

  const fetchFunnels = currentPage_ => {
    setShowModal(false)
    setIsLoading(true)
    const queryParams = new URLSearchParams({
      page: currentPage_,
      size: itemsPerPage,
    })
    if (store) {
      queryParams.append("store", store)
    }

    if (searchURL && searchURL.trim() !== "") {
      queryParams.append("url", searchURL.trim())
    }

    const paramsToSend = queryParams.toString()
    callGetApi(
      true,
      `/funnel?${paramsToSend}`,
      data => {
        setFunnels(data.content)
        setTotalPages(data.totalPages)
        setIsLoading(false)
      },
      errorMessage => {
        console.error("Failed to fetch funnels:", errorMessage)
        showErrorToastr(getFriendlyErrorMessage(errorMessage))
        setFunnels([])
        setIsLoading(false)
      }
    )
  }

  const deleteFunnel = funnelData => {
    const queryParams = new URLSearchParams({
      updatedBy: funnelData?.created_by,
    }).toString()

    if (!funnelData.id) {
      showErrorToastr("An error ocurred deleting the funnel")
      return
    }

    callDeleteApi(
      true,
      `/funnel/${funnelData?.id}?${queryParams}`,
      () => {
        fetchFunnels(currentPage)
        showSuccessToastr("Funnel successfully deleted")
      },
      errorMessage => {
        console.error("Failed to delete funnels:", errorMessage)
        showErrorToastr(getFriendlyErrorMessage(errorMessage))
      }
    )
  }

  const duplicateFunnel = funnel => {
    setCurrentFunnel(funnel)
    setIsDuplicating(true)
    setShowModal(true)
  }

  const editFunnel = funnel => {
    setCurrentFunnel(funnel)
    setIsDuplicating(false)
    setShowModal(true)
  }

  const seeFunnel = funnel => {
    const { id, title, description, store } = funnel
    if (!id) {
      showErrorToastr("An error ocurred")
      return
    }
    updateFunnelData({ id, title, description, store })
    navigate(`/funnels/draw-your-funnel/view/${id}`)
  }

  const confirmDeleteFunnel = funnel => {
    setConfirmAction({
      action: "delete",
      funnelData: { id: funnel.id, created_by: funnel.created_by },
    })
    setShowConfirmModal(true)
  }

  const reactivateFunnel = funnel => {
    callGetApi(
      true,
      `/funnel/file/${funnel.id}`,
      xmlData => {
        const formData = new FormData()
        formData.append("title", funnel.title)
        formData.append("description", funnel.description)
        formData.append("store", funnel.store)
        formData.append("is_active", true)
        formData.append("created_by", funnel.created_by)
        formData.append("updated_by", funnel.updated_by)
    
        formData.append(
          "file",
          new Blob([xmlData], { type: "application/xml" }),
          "diagram.bpmn"
        )
        callPutApi(true, `/funnel/${funnel.id}`, formData,
          () => {
            showSuccessToastr("Funnel activated successfully")
            fetchFunnels(currentPage)
          },
          errorMessage => {
            showErrorToastr(`Failed to save model: ${errorMessage}`)
          }
        )
      },
      xmlError => {
        showErrorToastr(`An error ocurred - ${xmlError}`)
        console.error("Failed to fetch funnel XML:", xmlError)
      }
    )  
  }

  const handleModalConfirmation = () => {
    if (confirmAction.action === "delete") {
      deleteFunnel(confirmAction.funnelData)
    }
    setShowConfirmModal(false)
  }

  const getShopLabelByValue = storeValue => {
    const shops = allShopsEnv[process.env.REACT_APP_ENV] || []
    const shop = shops.find(shop => shop.value === storeValue)
    return shop ? shop.label : "-"
  }

  const handlePageChange = page => {
    setCurrentPage(page)
  }

  const handleURLChange = (e) => {
    setSearchURL(e.target.value)
    setCurrentPage(1)
  }

  const handleStoreChange = (e) => {
    setStore(e.target?.value)
    setCurrentPage(1)
  }

  const toggleDropdown = (id) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  return (
    <div className="funnels-list-container">
      <Card className="funnels-list">
        <CardBody>
          <div className="flex flex-row action-bar mb-3">
            <div className="flex flex-row items-center justify-center gap-3 width-1/2">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-control"
                  placeholder="URL"
                  value={searchURL ?? ''}
                  onChange={handleURLChange}
                />
                <i className="fa fa-search search-icon"></i>
              </div>

              <div className="select-wrapper">
                <select
                  className="form-control select-store"
                  value={store ?? ''}
                  onChange={handleStoreChange}
                >
                  <option value="">Select Store</option>
                  {allShopsEnv[process.env.REACT_APP_ENV]?.map((option) => (
                    <option key={option?.value} value={option?.value}>
                      {option?.label}
                    </option>
                  ))}
                </select>
                <i className="fa fa-angle-down angle-icon"></i>
              </div>

            </div>
            <Button
              className="btn btn-success waves-effect waves-light create-funnel-btn"
              onClick={() => {
                setCurrentFunnel(null)
                setIsDuplicating(false)
                setShowModal(true)
              }}
              disabled={isLoading}
            >
              Create Funnel
            </Button>
          </div>
          {isLoading ? (
            <div className="spinner-loader">
              <Spinner className="ms-2" color="primary" />
            </div>
          ) : (
            <div className="table-responsive rounded-table">
              <Table className="table mb-0">
                <thead className='funnels-table-header'>
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Shop</th>
                    <th>Shopify Tag</th>
                    <th>Status</th>
                    <th>Created By</th>
                    <th>Created At</th>
                    <th>Updated By</th>
                    <th>Updated At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {funnels.length > 0 ? (
                    funnels?.map(funnel => (
                      <tr key={funnel.id}>
                        <td>{funnel.id}</td>
                        <td>{funnel.title}</td>
                        <td>{getShopLabelByValue(funnel.store)}</td>
                        <td
                          style={{
                            maxWidth: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {funnel.description}
                        </td>
                        <td>{funnel.is_active ? 'Active' : 'Inactive'}</td>
                        <td>{funnel.created_by || "-"}</td>
                        <td>{formatDate(funnel.created_at) || "-"}</td>
                        <td>{funnel.updated_by || "-"}</td>
                        <td>{formatDate(funnel.updated_at) || "-"}</td>
                        <td className='action-cell'>
                          <Dropdown
                              isOpen={dropdownOpen[funnel.id] || false}
                              toggle={() => toggleDropdown(funnel.id)}
                            >
                              <DropdownToggle
                                tag="span"
                                data-toggle="dropdown"
                                className="dropdown-toggle" 
                                aria-expanded={dropdownOpen[funnel.id] || false}
                                style={{ cursor: "pointer" }}
                              >
                                <i className="fa fa-ellipsis-v"></i>
                              </DropdownToggle>
                              <DropdownMenu right className="custom-dropdown-menu">
                                <DropdownItem onClick={() => editFunnel(funnel)}>
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => duplicateFunnel(funnel)}
                                >
                                  Duplicate
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => navigate(`/funnels/${funnel.id}/pages`)}
                                >
                                  Pages
                                </DropdownItem>
                                {
                                  funnel.is_active ? (
                                  <DropdownItem
                                    className="text-danger"
                                    onClick={() => confirmDeleteFunnel(funnel)}
                                  >
                                    Deactivate
                                  </DropdownItem>
                                ) : (
                                  <DropdownItem
                                    className="text-success"
                                    onClick={() => reactivateFunnel(funnel)}
                                  >
                                    Activate
                                  </DropdownItem>
                                )
                                }
                              </DropdownMenu>
                            </Dropdown>
                          </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No results
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </CardBody>
      </Card>
      {!isLoading && (
        <div className="pagination-listing-container">
          <Pagination aria-label="Page navigation example" style={{display: 'flex', gap: '10px'}}>

            <PaginationItem disabled={currentPage <= 1}>
              <PaginationLink
                style={{width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: currentPage <= 1 ? 'lightgray': '#142541', color: 'white'}}
                previous
                href="#"
                onClick={() => handlePageChange(currentPage - 1)}
              />
            </PaginationItem>

            {[...Array(totalPages)]?.map((page, i) => (
              <PaginationItem active={i + 1 === currentPage} key={i} style={{display: 'flex', alignItems: 'center'}}
              >
                <PaginationLink
                  style={{width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', color: 'black', border: i + 1 === currentPage ? '1px solid #142541' : ''}}
                  onClick={() => handlePageChange(i + 1)}
                  href="#"
                >
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}

            <PaginationItem disabled={currentPage >= totalPages}>
              <PaginationLink
                style={{width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: currentPage >= totalPages ? 'lightgray' : '#142541', color: 'white'}}
                next
                href="#"
                onClick={() => handlePageChange(currentPage + 1)}
              />
            </PaginationItem>
          </Pagination>
        </div>
      )}
      <CreateFunnelModal
        show={showModal}
        onCloseClick={() => setShowModal(false)}
        funnel={currentFunnel}
        isDuplicating={isDuplicating}
        modalTitle={isDuplicating ? "Duplicate Funnel" : currentFunnel? "Edit Funnel" : "Create Funnel"}
        onSaveSuccess={() => fetchFunnels(1)}
      />
      <ConfirmModal
        show={showConfirmModal}
        onConfirmClick={handleModalConfirmation}
        onCloseClick={() => setShowConfirmModal(false)}
        iconClass={confirmAction.action === "delete" ? 'fa fa-trash' : 'fa fa-copy' }
        isDelete= {confirmAction.action === "delete"}
        message={
          confirmAction.action === "delete"
            ? "Are you sure you want to delete this funnel?"
            : "Are you sure you want to duplicate this funnel?"
        }
      />
    </div>
  )
}

export default ListFunnels
